import Vue from "vue"
import { i18n } from './i18n/index.js';

Vue.filter("first4Chars", str => str.substring(0, 4));
Vue.filter("last4Chars", str => str.substring(str.length - 4));

function parseNumber(amount, toFixed) {
    if (i18n.locale === 'de') {
        return Number.parseFloat(amount).toFixed(toFixed).replace('.', ',').replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    } else {
        return Number.parseFloat(amount).toFixed(toFixed).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
    }
}

Vue.filter('currency', amount => {
    return parseNumber(amount, 2);
});

Vue.filter('vatNumber', amount => {
    return parseNumber(amount, 1);
});

Vue.filter('fiat', amount => {
    return parseNumber(amount, 2);
});

Vue.filter('currencyNatural', amount => {
    return parseNumber(amount, 0);
});

Vue.filter('numberNatural', amount => {
        return parseInt(amount);
});

Vue.filter('plantCurrency', amount => {
        return parseInt(amount);
});

Vue.filter('plants', amount => {
        return parseInt(amount);
});

Vue.filter('members', amount => {
        return parseInt(amount);
});

Vue.filter('cryptoCurrency', function (value) {
    return parseFloat(value).toFixed(0);
});

Vue.filter('cannabis', function (amount) {
    return parseNumber(amount, 2);
});

Vue.filter('pad', (num, size) => {
    var s = "000000000" + num;
    return s.substr(s.length-size);
});